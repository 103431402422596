import { combineReducers } from "redux";
import TvchannelReducer from "./TvchannelReducer";
import GenerReducer from "./GenerReducer";
import FilterReducer from "./FilterReducer";
import MetaReducer from "./metaReducer";

const rootReducer = combineReducers({
        ChannelObj : TvchannelReducer,
        GenerObj : GenerReducer,
        FilterObj : FilterReducer,
        MetaObj : MetaReducer
});

export default rootReducer;