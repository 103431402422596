import "./ActorDetail.css";
import Container from "react-bootstrap/Container";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useQuery } from "../Search/search";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useEffect, useState } from "react";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import HeadTags from "../../common/Helmet";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";

const prefix = 'https://image.tmdb.org/t/p/original/'


const ActorDetail = () => {
    const history = useHistory()
    let query = useQuery();
    const SeriesId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));
    const [item, setItem] = useState({})
    const [Tag, setTag] = useState([])
    const id = query.get('id')
    const [relatedmovie, setrelatedmovie] = useState([])

    const dispatch = useDispatch();

    const GotoMovieDetaile = (id, name) => {
        localStorage.setItem('selectedChannel', id);
        dispatch(
            setSelectedTvChannel(id)
        ).then((res) => {
            const URL = id + "/" + name.replace(/\s+/g, '-')
            history.push('/Movie/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }


    const Detailes = async () => {
        await axios.get(APIUrl + `api/tv/artist/${SeriesId}`).then((res) => {

            const arr = res.data
            cacheable(localStorage.setItem('testList',JSON.stringify(res.data)))
            Metadispatch( `Artist | `+ res.data.name, res.data.biography, res.data.profile_image)
            setItem(res.data)
            // let TagArr = [];

            // arr.also_known_as.map((item) => {
            //     TagArr.push(item)
            // })
            setTag(arr.also_known_as)

        })
    }

    const Metadispatch = (title, desc, image) => {

		dispatch(
			setSelectedMeta(title, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}
    const relatedHandler = async () => {

        await axios.get(APIUrl + `api/tv/artist-movies/${SeriesId}?page=1`).then((res) => {
            setrelatedmovie(res.data.results)
        })
    }

    // const tag = ()=>{
    //     axios.get(APIUrl + `api/tv/artist/${query.get('id')}/`).then((res)=>{

    //     })
    // }


    useEffect(() => {
        Detailes();
        relatedHandler();

    }, [])


    return (
        <ContentWrapper className="single-channel-page">
            {
                SeriesId ?
                <Container fluid className="toni_content">


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="toni_bg  position-relative" style={{ backgroundImage: `url(${item.profile_image})` }}></div>
                                <div className="d-flex align-items-end ac_content position-absolute">
                                    <div className="acotrlogo text-start ">
                                        <img className="overflow-hidden" src={item.profile_image}></img>
                                    </div>
                                    <div className="actor_con mb-3">
                                        <h3 className="text-white">{item.name}</h3>
                                        <p className="text-white">{item.known_for_department} | {item.birthday} | {item.place_of_birth}</p>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div >
                            <div >
                                <div className="tonisub_content">
                                    <h6 className="text-white">Bio</h6>
                                    <p>{item.biography ? item.biography : "No biography"}</p>
                                    {
                                        Tag.length ?
                                            <div className="col-lg-12">
                                                <div className="d-flex align-items-center cut_btn">

                                                    <div className="btn_cu1"><FontAwesomeIcon icon={faTag} className="text-white" /><span className="fs-5 text-white mb-0 ml-1">Tags</span></div>

                                                    <div className="d-flex flex-wrap align-items-center New_Other_btn px-0 ml-3">
                                                        {

                                                            Tag.map((item) => {
                                                                return (
                                                                    <div className="rounded-pill text-white bg-white py-1 px-3 d-inline block">{item}</div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                            </div> : " "}

                                            <div class="sharethis-inline-reaction-buttons"></div>


                                    <h6 className="fli_text">Filmography</h6>
                                    <div className="d-flex align-items-center flex-wrap ">
                                        <div className="row">
                                            {
                                                relatedmovie.map((res) => {

                                                    return (
                                                        res.backdrop_path &&
                                                        <a href={'/Movie/' +res.id + "/" + res.original_title.replace(/\s+/g, '-')} onClick={()=>GotoMovieDetaile(res.id , res.original_title)} key={res} className=" py-1 px-1 col-sm-6 col-lg-2 col-md-4">
                                                            <img className="overflow-hidden" src={`https://image.tmdb.org/t/p/original${res.backdrop_path}`}></img>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>







                    </Container>
                    :
                    // <img ></img>
                    <h1>No artist Found</h1>
            }

            {/* <ContentWrapper className="single-channel-page">
                <Container>
                    
                        id ?
                    
                    
                    
                </Container>
            </ContentWrapper> */}
        </ContentWrapper>
    );
};

export default ActorDetail;
