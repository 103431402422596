import "./SingleChannel.css";
import Container from "react-bootstrap/Container";
import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import { useQuery } from "../Search/search";
import axios from "axios";
import SingleChannel from "../SingleChannel/SingleChannel";
import SingleChannelHero from  "../SingleChannel/SingleChannelHero"
import APIUrl from "../../common/Urlcontant";
import { useSelector } from "react-redux";

const Movie = () => {
	let query = useQuery();
	const [list, setList] = useState({})
	const [Country, setCountry] = useState('')
	const [flag, setflag] = useState('')
	const [genre, setgenre] = useState([])
	const [ genId , setgenId ] = useState(query.get('genreID'));
	const GenerId = useSelector((state) => state.GenerObj.selectedGenerId );
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const id = query.get('id');
	const genreID = query.get('genreId');
	

	useEffect(() => {
		window.scrollTo(0, 0)

	
	}, [])

	return (

		<ContentWrapper className="single-channel-page">
			<Container fluid>
			
					
						{/* <SingleChannelNav /> */}

						<ChannelVideos GenerId={GenerId} />
					
				
					
			
			</Container>
		</ContentWrapper>



	);
};

export default Movie;
