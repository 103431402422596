import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Navigation from "./components/Navigation/Navigation";
import Sidebar from "./components/Sidebar/Sidebar";

import SingleChannel from "./components/SingleChannel/SingleChannel";
import VideoPage from "./components/VideoPage/VideoPage";

import FourZeroFour from "./components/FourZeroFour/FourZeroFour";
import Contact from "./components/Contact/Contact";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import Auth from "./components/Auth/Auth";
import Settings from "./components/Settings/Settings";

import { CustomScrollToTop } from "./components/Atomics/ScrollToTop/ScrollToTop";
import Search from "./components/Search/search";
import Movie from "./components/Movie/Movie";
import Actors from "./components/Actors/Actors";
import ActorDetail from "./components/Actors/ActorDetail";
import Birthday from "./components/Birthday/Birthday";
import SingleChannelHero from "./components/SingleChannel/SingleChannelHero";
import { useSelector } from "react-redux";
import MetaTag from "./common/MetaTag";
import { HelmetProvider } from "react-helmet-async";


function App() {
	const [showMenu, setShowMenu] = useState(true);


	useEffect(() => {
		if (showMenu) {
			document.body.classList.add("sidebar-toggled");
		} else {
			document.body.classList.remove("sidebar-toggled");
		}
	}, [showMenu]);



	return (
		<Router>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/">
					<Content showMenu={showMenu} setShowMenu={setShowMenu} />
				</Route>
				{/* <Route path="/Movies" component={Movie} /> */}
				<Route path="*" component={FourZeroFour} />
			</Switch>
		</Router>
	);
}

function Content(props) {
	const GenerId = useSelector((state) => state.GenerObj.selectedGenerId);
	const FilterId = useSelector((state) => state.FilterObj.selectedFilterId );


	return (
		<>
		 <HelmetProvider>
          <MetaTag/>
			<div className="homepage-wrapper">
				<Navigation props={props} />
				<div id="wrapper" style={{ overflow: 'hidden !important' }}>
					<Sidebar props={props} />

					<Switch>
					
						{GenerId || FilterId ?
							<Route exact path="/Movies/:id/:name">
								<Movie />
							</Route>
							: <Route exact path="/Movies">
								<Movie />
							</Route>}


						<Route exact path="/Movie/:id/:name">
							<SingleChannelHero />
						</Route>

						<Route exact path="/">
							<Birthday />
						</Route>
						<Route exact path="/Actor/:id/:name">
							<ActorDetail />
						</Route>
						
						<Route exact path="/Actors">
							<Actors />
						</Route>
						
						

						<Route exact path="/search">
							<Search />
						</Route>


						<Route path="*" component={FourZeroFour} />
					</Switch>
				</div>
				<ScrollToTop
					smooth
					component={<CustomScrollToTop />}
					className="scroll-to-top outline-0"
					color="white"
				/>
			</div>
			</HelmetProvider>
		</>
	);
}

export default App;
