import React, { useEffect, useState } from "react";
import './search.css'
import { Button, Form } from "react-bootstrap";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useHistory } from "react-router-dom";
import axios from "axios";

// import '../../assest/js/custom'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ImgUrl from '../../img/tv.jpg'
import FatFooter from "../Footer/FatFooter";
import APIUrl from "../../common/Urlcontant";
import { useDispatch } from "react-redux";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Search = () => {
  let query = useQuery();
  const history = useHistory();
  const [list, setlist] = useState([])
  const [ActorList, setActorList] = useState([])
  const [Tvchannel, setTvchannel] = useState([])
  const [Series, setSeries] = useState([])
  const [language, setlanguage] = useState('')


  const apiKey = '8c8960e929b3daeea44acef93cd6fdf5';
  const prefix = 'https://image.tmdb.org/t/p/original/'
  const dispatch = useDispatch();


  const getLanguage = (code) => {
    const lang = new Intl.DisplayNames(['en'], { type: 'language' });
    return lang.of(code);
  }
  const SearchHandler = (e) => {

    if (query.get('query')) {
      axios.get(APIUrl + `api/tv/search/?query=${query.get('query')}`).then((res) => {
        try {
          if (res) {

            const data = res.data.movies.forEach((el) => {
              el.LanguageFullName = getLanguage(el.original_language);
            })
            const Sdata = res.data.series.forEach((el) => {
              el.LanguageFullName = getLanguage(el.original_language);
            })
          
            setActorList(res.data.artist)
           




          }

        } catch (error) {

        }
      })
    }

  };


    const GotoActorDetaile = (Aid, Aname) => {
      localStorage.setItem('selectedChannel', Aid);
      dispatch(
          setSelectedTvChannel(Aid)
      ).then((res) => {
          const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
          history.push('/Actor/' + URL );

      }).catch((err) => {
          console.log("redux ---", err);
      })
  }

  

  useEffect(() => {
    SearchHandler();
    // ActorSearchResult();
  }, [query.get('query')])


  return (
    <>
      {
        // Tvchannel.length && list.length && Series.length && ActorList.length ?
          <ContentWrapper className="single-channel-page">



            {
              ActorList.length ?
                <div class="video-block section-padding">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="main-title">
                        <br></br>
                        <br></br>
                        <h6>Actor search results</h6>
                      </div>
                    </div>

                    <div class="grid">
                      {
                        ActorList.map((item, index) => {
                          return (
                            item.profile_path &&

                            <div key={index}>
                              <a class="video-card"  href={ '/Actor/'+item.id + "/" + item.name.replace(/\s+/g, '-')}  onClick={() => { GotoActorDetaile(item.id , item.name) }}>
                                <div class="video-card-image">
                                  <a ><img class="grid_item" style={{ Height: "300px" }} src={prefix + item.profile_path} alt="" /></a>
                                  <div class="video-card-body">
                                    <div class="video-title">
                                      <a >{item.name}</a>
                                    </div>
                                  </div>
                                </div>

                              </a>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div> : <h3 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }}>No Actor found</h3>
            }



          </ContentWrapper>

          // : <h1 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }} > No Search result found </h1>
      }
      <FatFooter />
    </>
  )
}

export default Search;